// Reference for theme variables expected by styled-system/Rebass:
// https://github.com/jxnblk/styled-system/blob/master/docs/table.md
export default {
  breakpoints: ['600px', '960px', '1200px'],
  // breakpoints: ['20em', '30em', '40em'],

  space: {
    0: 0,
    none: 0,
    xxs: 2,
    xs: 4,
    sm: 8,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 44,
  },

  fontSizes: {
    xxs: 9,
    xs: 10,
    sm: 12,
    md: 14,
    lg: 18,
    xl: 24,
    xxl: 40,
  },

  colors: {
    siteNavBg: '#111',
    primary: '#FF0A8E',
    purple: '#755BFF',
    text: '#a5aeb9',
    secondaryBackground: '#121419',
  },

  styles: {
    h1: {
      fontSize: ['2rem'],
      lineHeight: '1.25',
    },
    blockSubtitle: {
      color: 'primary',
      fontSize: '.75rem',
      fontWeight: 600,
      textTransform: 'uppercase',
      letterSpacing: '1px',
    }
  }
}
